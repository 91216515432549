<div class="blur-content">
  <div [ngClass]="{ 'page-container': true, sbar_collapsed: mobileView }" style="background-color: #f2f8fa !important">
    <app-sidebar-menu [selected]="selected" [isAdmin]="true"></app-sidebar-menu>
    <!-- main content area start -->
    <div class="main-content">
      <app-header></app-header>
      <div class="main-content-inner">
        <!-- market value area start -->
        <div class="row mt-5">
          <div class="col-12" style="padding-right: 0 !important; margin-right: 0 !important">
            <div class="card">
              <div class="card-body">
                <div *ngIf="loadingWorkflow===false">
                  <div class="d-flex">
                    <h4>Workflow Actions</h4>
                  </div>
                  <hr>
                  <div class="table-responsive">
                    <table class="table table-sm text-sm table-bordered">
                      <thead>
                        <tr class="text-center">
                          <th>
                            <div class="flex-container" (click)="sort('workflowTitle')">
                              Workflow Title <span ><i class="fa fa-sort"></i></span>
                            </div>
                          </th>
                          <th>
                            <div class="flex-container" (click)="sort('entityName')">
                              Entity Name <span ><i class="fa fa-sort"></i></span>
                            </div>
                          </th>
                          <th>
                            <div class="flex-container" (click)="sort('triggerEntityId')">
                              Entity Id <span ><i class="fa fa-sort"></i></span>
                            </div>
                          </th>
                          <th>
                            <div class="flex-container" (click)="sort('stepNumber')">
                              Step <span ><i class="fa fa-sort"></i></span>
                            </div>
                          </th>
                          <th>
                            <div class="flex-container" (click)="sort('title')">
                              Action <span ><i class="fa fa-sort"></i></span>
                            </div>
                          </th>
                          <th>
                            <div class="flex-container" (click)="sort('departmentName')">
                              Department <span ><i class="fa fa-sort"></i></span>
                            </div>
                          </th>
                          <th>
                            <div class="flex-container" (click)="sort('createdAT')">
                              Date Created <span ><i class="fa fa-sort"></i></span>
                            </div>
                          </th>
                          <th>
                            <div class="flex-container" (click)="sort('dueDate')">
                              Date Due <span ><i class="fa fa-sort"></i></span>
                            </div>
                          </th>
                          <th>
                            <div class="flex-container" (click)="sort('status')">
                              Status <span ><i class="fa fa-sort"></i></span>
                            </div>
                          </th>
                          <th class="text-center">File</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>                                                
                        <tr>
                          <td>
                            <div class="search-container">
                              <input type="text" [(ngModel)]="searchWorkflowTitle" (ngModelChange)="applyFilter()"
                                class="form-control search-input" placeholder="Search...">
                              <app-search-icon class="search-icon"></app-search-icon>
                            </div>
                          </td>
                          <td>
                            <div class="search-container">
                              <input type="text" [(ngModel)]="searchEntityName" (ngModelChange)="applyFilter()"
                                class="form-control search-input" placeholder="Search...">
                              <app-search-icon class="search-icon"></app-search-icon>
                            </div>
                          </td>
                          <td>
                            <div class="search-container">
                              <input type="text" [(ngModel)]="searchTriggerEntityId" (ngModelChange)="applyFilter()"
                                class="form-control search-input" placeholder="Search...">
                              <app-search-icon class="search-icon"></app-search-icon>
                            </div>
                          </td>
                          <td></td>
                          <td>
                            <div class="search-container">
                              <input type="text" [(ngModel)]="searchStepTitle" (ngModelChange)="applyFilter()"
                                class="form-control search-input" placeholder="Search...">
                              <app-search-icon class="search-icon"></app-search-icon>
                            </div>
                          </td>
                          <td>
                            <div class="search-container">
                              <input type="text" [(ngModel)]="searchDepartmentName" (ngModelChange)="applyFilter()"
                                class="form-control search-input" placeholder="Search...">
                              <app-search-icon class="search-icon"></app-search-icon>
                            </div>
                          </td>
                          <td></td>
                          <td></td>
                          <td>
                            <div class="search-container">
                              <input type="text" [(ngModel)]="searchStepStatus" (ngModelChange)="applyFilter()"
                                class="form-control search-input" placeholder="Search...">
                              <app-search-icon class="search-icon"></app-search-icon>
                            </div>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                        <ng-container *ngIf="workFlowsData.length>0">
                        <tr *ngFor="let item of workFlowsData">
                          <td>{{ item.workflowTitle }}</td>
                          <td>{{ item.entityName }}</td>
                          <td>{{ item.triggerEntityId }}</td>
                          <td>{{ item.stepNumber }}</td>
                          <td>{{ item.title }}</td>
                          <td>{{ item.departmentName }}</td>
                          <td>{{ item.createdAT | date: 'dd-MM-yyyy' }}</td>
                          <td>
                            <input type="date" (keydown)="onKeyDown($event)" title="Select Date From Calendar"
                              class="form-control" [(ngModel)]="item.dueDate"
                              (ngModelChange)="onDueDateChange(item)">
                          </td>
                          <td>
                            <select id="status" name="status" class="custom-select custom-select-sm"
                              [(ngModel)]="item.status" (change)="onStatusChange(item)">
                              <option value="" disabled selected> Status</option>
                              <option *ngFor="let value of status" [value]='value'>{{ value }}</option>
                            </select>
                          </td>
                          <td>
                            <button class="btn btn-sm btn-info"
                              [title]="item.fileName && item.fileId ? 'View File' : 'No File'"
                              (click)="onClickView(item)" [disabled]="!item.fileName || !item.fileId">
                              <i class="fa fa-file-text-o" style="font-size: 18px"></i>
                            </button>
                          </td>
                          <td class="text-center">
                            <button title="Delete" class="btn btn-sm btn-info ml-1"
                                (click)="deleteworkflowAction(item)">
                                <i class="fa fa-trash-o" style="font-size: 18px"></i>
                            </button>
                        </td>
                        </tr>
                      </ng-container>
                      <tr *ngIf="workFlowsData.length<1">
                        <td class="text-center" colspan="10">No Data</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
                <div *ngIf="loadingWorkflow===true">
                  <li class="t_loader">
                    <div class="card__desc load" id="long"></div>
                  </li>
                  <li class="t_loader">
                    <div class="card__desc load"></div>
                  </li>
                  <li class="t_loader">
                    <div class="card__desc load" id="long"></div>
                  </li>
                  <li class="t_loader">
                    <div class="card__desc load"></div>
                  </li>
                  <li class="t_loader">
                    <div class="card__desc load" id="long"></div>
                  </li>
                  <li class="t_loader">
                    <div class="card__desc load"></div>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- market value area end -->
      </div>
      <!-- main content area end -->
    </div>
  </div>
</div>

<app-confirmation-modal 
  [title]="'Delete Workflow Action'"
  [message]="'Are you sure you want to delete this Workflow Action?'"
  [note]="'(Note: This action cannot be undone)'"
  [confirmButtonText]="'Delete'"
  [cancelButtonText]="'Cancel'"
  [successMessage]="successMessage"
  [loading]="loading"
  [(isVisible)]="isModalVisible"
  (confirm)="confirmDelete()"
  (cancel)="onCancel()"
  (close)="onClose()"
></app-confirmation-modal>