<div class="blur-content">
    <div  [ngClass]="{ 'page-container': true, sbar_collapsed: mobileView }" style="background-color: #f2f8fa !important">
      <app-sidebar-menu [selected]="selected" [isAdmin]="true"></app-sidebar-menu>
      <!-- main content area start -->
      <div class="main-content">
        <app-header></app-header>
        <div class="main-content-inner">
          <!-- market value area start -->
          <div class="row mt-5">
            <div class="col-12" style="padding-right: 0 !important; margin-right: 0 !important">
              <div class="card">
                <div class="card-body">
                  <div *ngIf="loadingDeductions===false">
                    <h4>Pending Deductions</h4>                   
                    <hr>
                    <div class="table-responsive">
                      <table class="table table-sm table-hover table-bordered">
                        <thead>
                          <tr class="align-top text-center">
                            <th>ID<br/>
                              <div class="search-container">
                                <input type="text" [(ngModel)]="filter.id" (ngModelChange)="applyFilter()"
                                  class="form-control search-input" placeholder="Search...">
                                <app-search-icon class="search-icon"></app-search-icon>
                              </div>                              
                            <th>Candidate<br/>
                              <div class="search-container">
                                <input type="text" [(ngModel)]="filter.candidateName" (ngModelChange)="applyFilter()"
                                  class="form-control search-input" placeholder="Search...">
                                <app-search-icon class="search-icon"></app-search-icon>
                              </div></th>
                            <th>Deduction <br/>
                              <div class="search-container">
                                <input type="text" [(ngModel)]="filter.name" (ngModelChange)="applyFilter()"
                                  class="form-control search-input" placeholder="Search...">
                                <app-search-icon class="search-icon"></app-search-icon>
                              </div>                             
                            <th>Employer</th>
                            <th>Type</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Amount</th>
                            <th>$ Limit</th>
                            <th>Note<br/>
                              <div class="search-container">
                                <input type="text" [(ngModel)]="filter.note" (ngModelChange)="applyFilter()"
                                  class="form-control search-input" placeholder="Search...">
                                <app-search-icon class="search-icon"></app-search-icon>
                              </div>
                            <th colspan="2"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngIf="filteredDeductions.length > 0">
                            <tr *ngFor="let item of filteredDeductions">
                              <td>{{item.uid}}</td>
                              <td>{{item.candidateName}}</td>
                              <td>{{item.name}}</td>
                              <td>{{item.employer}}</td>
                              <td>{{(item.limit_type=="fixed_amount" || item.limit_type=='fixed_pay_period' )?'Fixed':(item.limit_type=="unlimited")?'Recurring':'-' }}</td>
                              <td>{{item.date_from ? (item.date_from | date: 'yyyy-MM-dd') : '-'}}</td>
                              <td>{{item.date_to ? (item.date_to | date: 'yyyy-MM-dd') : '-'}}</td>
                              <td>{{item.amount ? (item.amount | number:'1.2-2') : '-'}}</td>
                              <td>{{item.limit_fixed ? (item.limit_fixed | number:'1.2-2') : '-'}}</td>
                              <td>{{item.note ? item.note : '-' }}</td>
                              <td><button class="btn btn-sm btn-info" (click)="editDeduction(item)" data-toggle="modal" data-target="#deductionModal">Edit</button></td>
                              <td><button class="btn btn-sm btn-danger" (click)="removededuction(item)" data-toggle="modal" data-target="#deductionModal">Cancel</button></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="filteredDeductions.length < 1">
                            <tr>
                              <td colspan="11" class="text-center">No Data</td>
                            </tr>
                          </ng-container>                      
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div *ngIf="loadingDeductions===true">
                    <li class="t_loader">
                      <div class="card__desc load" id="long"></div>
                    </li>
                    <li class="t_loader">
                      <div class="card__desc load"></div>
                    </li>
                    <li class="t_loader">
                      <div class="card__desc load" id="long"></div>
                    </li>
                    <li class="t_loader">
                      <div class="card__desc load"></div>
                    </li>
                    <li class="t_loader">
                      <div class="card__desc load" id="long"></div>
                    </li>
                    <li class="t_loader">
                      <div class="card__desc load"></div>
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- market value area end -->
        </div>
        <!-- main content area end -->
      </div>
    </div>
  </div> 
  
  <!-- Modal -->
  <div class="modal fade" id="deductionModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" *ngIf="removeDeductionFlag">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Pending Deductions</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body d-flex justify-content-center">
        <div *ngIf="!message" class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div *ngIf="message">
          <p class="text-center px-5">{{ message }}</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-dismiss="modal" (click)="confirmCancel()">Yes</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
      </div>
    </div>
    <div class="modal-content" *ngIf="editDeductionFlag">
      <div class="modal-header">
        <h5 class="modal-title">Edit Deduction</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      
      <div class="modal-body">
        <!-- Alert message for failed submission -->
        <div *ngIf="submitFailed" class="alert alert-danger" role="alert">
          Failed to submit the form. Please try again.
        </div>
        <!-- Show success message and close button if form is submitted -->
        <div *ngIf="isFormSubmitted"> 
          <p class="text-center">{{ message }}</p>
          <div class="d-flex justify-content-center mt-3">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
          </div>
        </div>
        <!-- Show form if form is not yet submitted -->
        <form *ngIf="!isFormSubmitted" #editDeductionForm="ngForm">
          <div class="table-responsive">
            <table *ngIf="!isLoading" class="table table-sm">
              <tbody>
                <tr>
                  <td><label>ID</label></td>
                  <td><input type="text" disabled class="form-control" [(ngModel)]="editData.id" name="id" readonly /></td>
                </tr>
                <tr>
                  <td><label>Candidate</label></td>
                  <td><input type="text" disabled class="form-control" [(ngModel)]="editData.candidateName" name="candidateName" /></td>
                </tr>
                <tr>
                  <td><label>Deduction</label></td>
                  <td><input type="text" disabled class="form-control" [(ngModel)]="editData.name" name="deductionName" /></td>
                </tr>
                <tr>
                  <td><label>Employer</label></td>
                  <td><input type="text" disabled class="form-control" [(ngModel)]="editData.employer" name="employer" /></td>
                </tr>
                <tr>
                  <td><label>Type</label></td>
                  <td><input type="text" disabled class="form-control" [(ngModel)]="editData.limit_type" name="type" /></td>
                </tr>
                <tr>
                  <td><label>Start Date</label></td>
                  <td><input type="date" #startDate="ngModel" class="form-control" id="startDate"  [ngClass]="{'is-invalid': editDataErrors.startDate}" required
                     [(ngModel)]="editData.date_from" name="startDate"/>
                    <div *ngIf="editDataErrors.startDate" class="invalid-feedback">{{ editDataErrors.startDate }}</div>
                  </td>
                </tr>
                <tr>
                  <td><label>End Date</label></td>  
                  <td><input type="date" class="form-control" [disabled]="editData.limit_type==='fixed_amount'" [(ngModel)]="editData.date_to" name="endDate" /></td>
                </tr>
                <tr>
                  <td><label>Amount</label></td>
                  <td><input type="number" class="form-control"  #amount="ngModel" id="amount" required [ngClass]="{'is-invalid': editDataErrors.amount}" [(ngModel)]="editData.amount" name="amount" />
                    <div *ngIf="editDataErrors.amount" class="invalid-feedback">{{ editDataErrors.amount }}</div>
                  </td>
                </tr>
                <tr>
                  <td><label>Limit</label></td>
                  <td><input type="text" id="limitFixed" [disabled]="editData.limit_type==='unlimited'"  required #limit_fixed="ngModel" [ngClass]="{'is-invalid': editDataErrors.limit_fixed}" class="form-control" [(ngModel)]="editData.limit_fixed" name="limit" />
                    <div *ngIf="editDataErrors.limit_fixed"  class="invalid-feedback">{{ editDataErrors.limit_fixed }}</div>
                  </td>
                </tr>
                <tr>
                  <td><label>Note</label></td>
                  <td><input type="text" class="form-control" [(ngModel)]="editData.note" name="note" /></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="d-flex justify-content-center mt-1">
            <button  *ngIf="!isLoading" type="button" class="btn btn-sm btn-primary" (click)="submitEditDeduction()">
              Submit
            </button>
            <div *ngIf="isLoading" class="spinner-border text-primary" role="status"ß>
              <span class="sr-only">Loading...</span>
          </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
  