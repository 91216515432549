export const STATUS_VALUES = [
    "Permanent Placement",
    "Active",
    "Approve",
    "Archive",
    "Call",
    "Check",
    "Complete",
    "Former Contact",
    "Imported",
    "Inactive",
    "Incomplete",
    "Maybe",
    "Missed Call",
    "New",
    "New Lead",
    "Not Suitable",
    "On-Board",
    "On-Board (Fee Only)",
    "On-Board (Pre-Arrival)",
    "Overseas",
    "Placed",
    "Private",
    "Redeploy",
    "Rejected",
    "Screen",
    "Shortlisted",
    "Soft Unsuitable",
    "Unsuitable",
    "Waiting",
    "Waiting for Candidate",
  ];
  