import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient} from '@angular/common/http';
import { WorkflowAction } from 'src/app/models/WorkflowActions';


@Component({
  selector: 'app-workflow-actions',
  templateUrl: './workflow-actions.component.html',
  styleUrls: ['./workflow-actions.component.css']
})
export class WorkflowActionsComponent implements OnInit {
  loadingWorkflow:boolean=true;
  selected:string='workflow-actions'
  mobileView: boolean = false;
  loading: boolean = false;
  status:string[]=['New', 'In Progress', 'Complete', 'Cancelled'];
  sorting: boolean = false;
  workFlowsData: WorkflowAction[] = [];
  transformedData: WorkflowAction[] = [];
  // Search filter properties
  searchWorkflowTitle: string = '';
  searchTriggerName: string = '';
  searchTriggerEntityId: string = '';
  searchStepTitle: string = '';
  searchDepartmentName: string = '';
  searchStepStatus: string = '';
  searchEntityName: string = '';
  currentSortColumn: string = '';
  sortDirection: boolean = true; // true for ascending, false for descending
  file:any;
  successMessage:string='';
  workflowToDelete: WorkflowAction | null = null;
  isModalVisible: boolean = false;

  constructor(private http: HttpClient,private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.getAllWorkflows()
  }

  getAllWorkflows() {
    this.loadingWorkflow = true;
    const url = `${environment.apiConfig.uri}/admin/Candidates/GetAllWorkflowActions`;
    this.http.get(url).subscribe(
      (response: any) => {
        if (response.isSuccess) {
          this.workFlowsData = response.data.map((workflow: WorkflowAction) => {
            return {
              ...workflow,
              createdAT: this.convertToLocalTime(workflow.createdAT), 
              dueDate: workflow.dueDate ? this.formatDate(workflow.dueDate) : null
            };
          });
          this.transformedData=this.workFlowsData;
        } else {
          console.error("Failed to fetch workflows:", response.message);
        }        
        this.loadingWorkflow = false;
      },
      (error) => {
        console.error("Error fetching workflows:", error);
        this.loadingWorkflow = false;
      }
    );
  }  

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  private convertToLocalTime(utcDate: string): string | Date {
    const localDate = new Date(`${utcDate}Z`);
    return localDate;
  }

  deleteworkflowAction(workflow:WorkflowAction){
    this.workflowToDelete = workflow;
    this.isModalVisible = true;
    this.successMessage='';
    this.cdr.detectChanges();
  }

 
  confirmDelete() {
    if (this.workflowToDelete) {
      this.loading = true;
      const { id, stepId } = this.workflowToDelete;
      const url = `${environment.apiConfig.uri}/admin/Candidates/DeleteWorkFlowAction?Id=${id}&stepId=${stepId}`; 
      this.http.delete(url).subscribe(
        (res: any) => {
          // Remove the deleted workflow from workFlowsData
          this.workFlowsData = this.workFlowsData.filter(workflow => !(workflow.id === id && workflow.stepId === stepId));
          // Update transformedData if it depends on workFlowsData
          // this.transformedData = [...this.workFlowsData];
          this.successMessage = 'Workflow Action Deleted Successfully.';
          this.loading = false;
          this.isModalVisible = true;
        },
        (error) => {
          console.error('Error deleting workflow action:', error);
          this.loading = false;
          this.isModalVisible = true;
        }
      );
    }
  }

  onCancel() {
    this.isModalVisible = false;
  }

  onClose() {
    this.isModalVisible = false;
  }

  getFileStream(item: any, view: boolean = false) {
    const url = `${environment.apiConfig.uri}/admin/File/GetWorkFlowFileStream/${item.fileId}`;
    this.http.get(url).subscribe(
      (response: any) => {
        this.file = response.data || '';
        item.fileStream = this.file;
        if (view) {
          this.onClickView(item);
        } else {
          this.onClickDownload(item);
        }
      },
      (error) => {
        console.error('Error fetching file stream:', error);
      }
    );
}

onClickDownload(item: any) {
    if (!item.fileStream) {
        this.getFileStream(item);
    } else if (item.fileStream && item.fileStream.trim().length > 0) {
        const link = document.createElement('a');
        link.download = `${item.fileName}`;
        const source = `data:${this.getMimeType(item.fileName)};base64,${item.fileStream}`;
        link.href = source;
        link.click();
    }
}

onClickView(item: any) {
  if (!item.fileStream) {
      this.getFileStream(item, true);
  } else if (item.fileStream && item.fileStream.trim().length > 0) {
      const mimeType = this.getMimeType(item.fileName);
      if (mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
          this.onClickDownload(item);
      } else {
          const blob = this.dataURItoBlob(item);
          const file = new File([blob], item.fileName, {
              type: mimeType,
          });
          const url = window.URL.createObjectURL(file);
          window.open(url);
      }
  }
}

dataURItoBlob(item: any) {
    const byteString = window.atob(item.fileStream);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], {
        type: this.getMimeType(item.fileName),
    });
    return blob;
}

getMimeType(fileName: string): string {
    const extension = fileName.split('.').pop()?.toLowerCase();
    switch (extension) {
        case 'pdf': return 'application/pdf';
        case 'jpg': 
        case 'jpeg': return 'image/jpeg';
        case 'png': return 'image/png';
        case 'gif': return 'image/gif';
        case 'mp4': return 'video/mp4';
        case 'mov': return 'video/quicktime';
        case 'avi': return 'video/x-msvideo';
        case 'mp3': return 'audio/mpeg';
        case 'webp': return 'image/webp';
        case 'docx': return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        default: return 'application/octet-stream';
    }
}

  applyFilter() {
    this.workFlowsData = this.transformedData.filter((item: { workflowTitle: string; entityName: string; triggerEntityId: { toString: () => string | string[]; }; title: string; departmentName: string; status: string; }) => {
      return (!this.searchWorkflowTitle || (item.workflowTitle && item.workflowTitle.toLowerCase().includes(this.searchWorkflowTitle.toLowerCase()))) &&
           (!this.searchEntityName || (item.entityName && item.entityName.toLowerCase().includes(this.searchEntityName.toLowerCase()))) &&
           (!this.searchTriggerEntityId || item.triggerEntityId.toString().includes(this.searchTriggerEntityId)) &&
           (!this.searchStepTitle || (item.title && item.title.toLowerCase().includes(this.searchStepTitle.toLowerCase()))) &&
           (!this.searchDepartmentName || (item.departmentName && item.departmentName.toLowerCase().includes(this.searchDepartmentName.toLowerCase()))) &&
           (!this.searchStepStatus || (item.status && item.status.toLowerCase().includes(this.searchStepStatus.toLowerCase())));;
    });
  }

  updateStep(id: number, status?: string, dueDate?: string) {
    let url = `${environment.apiConfig.uri}/admin/Candidates/UpdateStep?id=${id}`;
    if (status) {
      url += `&status=${encodeURIComponent(status)}`;
    }
    if (dueDate) {
      url += `&dueDate=${dueDate}`;
    }

    this.http.post(url, {}).subscribe(response => {
    }, error => {
      console.error('Error updating step', error);
    });
  }

  onDueDateChange(item: any) {
    this.updateStep(item.stepId, item.status, item.dueDate);
  }

  onStatusChange(item: any) {
    this.updateStep(item.stepId, item.status);
  }

  onKeyDown(event: KeyboardEvent) {
    if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'].indexOf(event.key) !== -1) {
      event.preventDefault();
    }
  }

  sort(column: string) {
    if (this.currentSortColumn === column) {
      this.sortDirection = !this.sortDirection;
    } else {
      this.currentSortColumn = column;
      this.sortDirection = true;
    }
    this.workFlowsData.sort((a: any, b: any) => {
      let comparison = 0;
  
      if (['workflowTitle', 'entityName', 'title', 'departmentName', 'status'].includes(column)) {
        const valueA = a[column]?.toString().trim().toLowerCase() || '';
        const valueB = b[column]?.toString().trim().toLowerCase() || '';
        comparison = valueA.localeCompare(valueB);
      } 
      else if (['triggerEntityId', 'stepNumber'].includes(column)) {
        comparison = a[column] - b[column];
      } 
      else if (['createdAT', 'dueDate'].includes(column)) {
        const dateA = new Date(a[column]);
        const dateB = new Date(b[column]);
        if (dateA > dateB) {
          comparison = 1;
        } else if (dateA < dateB) {
          comparison = -1;
        }
      }
      return this.sortDirection ? comparison : -comparison;
    });
  }
  
}
