<div class="blur-content">
    <div [ngClass]="{ 'page-container': true, sbar_collapsed: mobileView }"
        style="background-color: #f2f8fa !important">
        <app-sidebar-menu [selected]="selected" [isAdmin]="true"></app-sidebar-menu>
        <!-- main content area start -->
        <div class="main-content">
            <app-header></app-header>
            <div class="main-content-inner">
                <!-- market value area start -->
                <div class="row mt-5">
                    <div class="col-12" style="padding-right: 0 !important; margin-right: 0 !important">
                        <div class="card">
                            <div class="card-body">
                                <div *ngIf="loadingWorkflow===false">
                                    <div class="d-flex">
                                        <h4>Create Workflow</h4> 
                                        <button class="ml-5 btn btn-sm btn-success px-3"
                                            title="Add Workflow" data-toggle="modal"
                                            data-target="#staticBackdrop" (click)="createWorkflow()">+ New</button>
                                    </div>
                                    <hr>
                                    <div class="table-responsive">
                                        <table class="table table-sm table-hover table-bordered">
                                            <thead>
                                                <tr>
                                                    <!-- <th>Unique ID</th> -->
                                                    <th>
                                                      <div class="flex-container" (click)="sort('workflowTitle')">
                                                        Workflow <span ><i class="fa fa-sort"></i></span>
                                                      </div>
                                                    </th>
                                                    <th>
                                                      <div class="flex-container" (click)="sort('triggerName')">
                                                        Trigger <span ><i class="fa fa-sort"></i></span>
                                                      </div>
                                                    </th>
                                                    <th>
                                                      <div class="flex-container" (click)="sort('departmentName')">
                                                        Department <span ><i class="fa fa-sort"></i></span>
                                                      </div>
                                                    </th>
                                                    <th class="text-center">Edit</th>
                                                    <th class="text-center">Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody> 
                                              <ng-container *ngIf="allWorkFlows.length">
                                                <tr *ngFor="let item of allWorkFlows;index as i">
                                                    <td>{{item.workflowTitle}}</td>
                                                    <td>{{item.triggerName}}</td>
                                                    <td>{{item.departmentName}}</td>
                                                    <td class="text-center"><button class="btn btn-sm btn-info px-3"
                                                            title="Edit Workflow" data-toggle="modal"
                                                            data-target="#staticBackdrop"
                                                            (click)="editworkflow(item.workflowId)">Edit</button></td>
                                                    <td class="text-center">
                                                        <button title="Delete" class="btn btn-sm btn-info ml-1"
                                                            data-toggle="modal" data-target="#deleteModel"
                                                            (click)="deleteworkflow(item.workflowId)">
                                                            <i class="fa fa-trash-o" style="font-size: 18px"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                              </ng-container>                                                                                           
                                                <tr *ngIf="!allWorkFlows.length">
                                                  <td class="text-center" colspan="6">No Data</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div *ngIf="loadingWorkflow===true">
                                    <li class="t_loader">
                                        <div class="card__desc load" id="long"></div>
                                    </li>
                                    <li class="t_loader">
                                        <div class="card__desc load"></div>
                                    </li>
                                    <li class="t_loader">
                                        <div class="card__desc load" id="long"></div>
                                    </li>
                                    <li class="t_loader">
                                        <div class="card__desc load"></div>
                                    </li>
                                    <li class="t_loader">
                                        <div class="card__desc load" id="long"></div>
                                    </li>
                                    <li class="t_loader">
                                        <div class="card__desc load"></div>
                                    </li>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- market value area end -->
            </div>
            <!-- main content area end -->
        </div>
    </div>
</div>

<!-- Modal Delete File -->
<div class="modal fade" id="deleteModel" tabindex="-1" role="dialog" aria-labelledby="deleteModelCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header d-flex justify-content-center text-center">
                <h5 class="modal-title text-center" id="deleteModelTitle">Delete Workflow</h5>
                <button #closeModelDelete type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="!loading">
                <div *ngIf="!successMessage">
                    <div class="px-1 text-center">
                        <h5>Are you sure you want to delete this Workflow</h5>
                        <p class="text-danger">(Note: This action cannot be undone)</p>

                        <div class="pt-3">
                            <button class="btn btn-sm btn-danger px-4" (click)="confirmDelete()">
                                Delete
                            </button>
                            <button type="button" #cancelButton class="btn btn-sm btn-info px-4 ml-2" data-dismiss="modal"
                                    aria-label="Close">
                                Cancel
                            </button> 
                        </div>
                    </div>
                </div>
                <div  class="text-center" *ngIf="successMessage">
                  <p>{{successMessage}}</p>
                  <div>
                    <button type="button" #cancelButton class="btn btn-sm btn-info px-4 ml-2" data-dismiss="modal"
                    aria-label="Close">
                        Close
                    </button>
                  </div>
                  
                </div>
            </div>
            <div *ngIf="loading" class="d-flex my-5 justify-content-center">
              <div  class="spinner-border text-primary " role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div *ngIf="!unsavedModel">
        <div class="modal-header" class="d-flex justify-content-center mt-2">
            <h5 *ngIf="successMessage" class="modal-title text-center">Create Workflow</h5>
            <button  *ngIf="!successMessage" class="btn btn-sm btn-info px-3 mr-3 fixWidth" title="Add New Step" (click)="addStep()">Add New Step</button>
            <button *ngIf="!successMessage" class="btn btn-sm btn-info px-3 ml-3 fixWidth" title="Save Workflow" (click)="saveWorkflow()">Save</button>
        </div>
        <div class="modal-body">
          <form *ngIf="!loading"  [formGroup]="workflowForm" id="workflowForm">
            <div *ngIf="!successMessage">
              <div *ngIf="validationMessage" class="alert alert-danger">
                {{ validationMessage }}
              </div>
              <div class="mb-3">
                <label for="workflowTitle" class="form-label">Workflow Title</label>
                <input type="text" class="form-control" (input)="validationMessage = ''" placeholder="Workflow Title" id="workflowTitle" formControlName="workflowTitle" aria-describedby="workflowTitle" required>
              </div>
              <div class="mb-3">
                <label for="triggerTitle" class="form-label">Trigger</label>
                <select class="form-select form-control fixed-height-dropdown" (change)="validationMessage = ''" aria-label="Trigger Title" id="triggerTitle" formControlName="triggerTitle">
                  <option value="" disabled selected>Select Trigger</option>
                  <option *ngFor="let trigger of triggers" [value]="trigger">{{ trigger }}</option>
                </select>
              </div>
              <div class="mb-3">
                <label for="department" class="form-label">Department</label>
                <select class="form-select form-control" aria-label="Department" (change)="validationMessage = ''" id="department" formControlName="department">
                  <option value="" disabled selected>Select Department</option>
                  <option *ngFor="let item of departments" [value]="item.departmentName">{{item.departmentName}}</option>
                </select>
              </div>
              <div formArrayName="steps">
                <div *ngFor="let step of steps.controls; let i = index" [formGroupName]="i">
                  <label [for]="'step' + i" class="form-label">Step {{ i + 1 }}</label>
                  <div class="d-flex align-items-center step">
                    <div class="mb-3 flex-grow-1 mr-1">
                      <input type="text" class="form-control" title="Step Description" [id]="'step' + i" formControlName="title" aria-describedby="stepHelp" required>
                    </div>
                    <div class="mb-3 d-flex">
                      <button class="btn btn-sm btn-info" 
                              [title]="step.get('fileName')?.value && step.get('fileId')?.value ? 'View File' : 'No File'" 
                              (click)="onClickView(step)"  [disabled]="!step.get('fileName')?.value || !step.get('fileId')?.value">                             
                        <i class="fa fa-file-text-o" style="font-size: 18px"></i>
                      </button>
                      <div>
                        <button *ngIf="uploadingStepIndex !== i" title="Add File" type="button" id="addfileBtn" class="btn btn-sm btn-primary ml-1" (click)="triggerFileInput(i)">
                          <i class="fa fa-edit" style="font-size: 18px"></i>
                        </button> 
                        <button *ngIf="uploadingStepIndex === i" class="btn btn-sm btn-primary ml-1" type="button" id="spinnerBtn" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          <span class="sr-only">Loading...</span>
                        </button>
                      </div>
                      <button title="Delete" (click)="deleteStep(i)" class="btn btn-sm btn-info ml-1">
                        <i class="fa fa-trash-o" style="font-size: 18px"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div  class="text-center" *ngIf="successMessage">
              <p>{{successMessage}}</p>
            </div>
          </form>
          <div class="d-flex justify-content-center">
            <div *ngIf="loading" class="spinner-border text-primary " role="status">
              <span class="sr-only my-5 py-5">Loading...</span>
            </div>
          </div>
          <div>
            
          </div>
        </div>
      </div>

      <div *ngIf="unsavedModel">
        <div class="modal-header d-flex justify-content-center text-center">
            <h5 class="modal-title ">Unsaved Changes</h5>
        </div>
        <div class="modal-body text-center">
          <p>You have made changes. <br>Do you want to save or discard them?</p>
          <div class="button-container" style="display: flex; justify-content:center;">
            <button (click)="unsavedModel=false;successMessage=''" class="btn btn-sm btn-outline-secondary ">Cancel</button>                        
            <button class="btn btn-sm btn-outline-danger mx-3" (click)="discardChanges()" data-dismiss="modal">
              Discard
            </button>                          
            <button class="btn btn-sm btn-outline-success" style="cursor: pointer; " (click)="saveWorkflow();">
                Save
            </button>  
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <ng-container *ngIf="workflowForm.dirty && !isFormSaved; else elseBlock">
          <button *ngIf="!unsavedModel" type="button" id="closeBtn1" class="btn btn-sm btn-danger px-4" 
                  (click)="onCloseClick()">Close</button>
        </ng-container>        
        <ng-template #elseBlock>
          <button type="button" id="closeBtn2" class="btn btn-sm btn-danger px-4" data-dismiss="modal">Close</button>
        </ng-template>
      </div>
      <input type="file" #fileInput style="display: none;" (change)="onFileChange($event)" />
    </div>        
  </div>
</div>



