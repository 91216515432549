import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { JobDetails, MatchingCandidate, ApiResponse } from 'src/app/models/job-details.interface';
import { environment } from 'src/environments/environment';
import { STATUS_VALUES } from '../../../commons/status-values';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-job-match',
  templateUrl: './job-match.component.html',
  styleUrls: ['./job-match.component.css']
})
export class JobMatchComponent implements OnInit {
  selected: string = 'job-match';
  jobMatchForm: FormGroup;
  pageSizeOptions = [10, 20, 50];
  page: number = 1; // Specify type as number for consistency
  pageSize: number = 10; // Specify type as number for consistency
  collectionSize: number = 0;
  loading: boolean = false;
  errorMessage: string = '';
  jobDetails: JobDetails | null = null;
  jobKeywords: string[] = [];
  matchingCandidates: MatchingCandidate[] = [];
  jobMatch: boolean = true;
  statusValues = STATUS_VALUES;

  constructor(private fb: FormBuilder, private http: HttpClient,private sanitizer: DomSanitizer ) {
    this.jobMatchForm = this.fb.group({
      jobId: ['', Validators.required],
      submission: ['', Validators.required],
      age: ['', Validators.required],
      candidateStatus: ['', Validators.required],
      hasOwnCar: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  onSearch(page: number = this.page, pageSize: number = this.pageSize): void {
    if (this.jobMatchForm.invalid) {
      this.jobMatchForm.markAllAsTouched();
      return;
    }
    this.loading = true;
    this.errorMessage = ''; // Clear previous error message

    const formValues = this.jobMatchForm.value;
    const endpoint = `${environment.apiConfig.uri}/admin/Candidates/GetMatchingJobs`;
    const params = {
      jobId: formValues.jobId || '',
      submission: formValues.submission || '',
      status: formValues.candidateStatus || '',
      days: formValues.age || '',
      hasCar: formValues.hasOwnCar || '',
      pageNumber: page.toString(),
      pageSize: pageSize.toString()
    };

    this.http.get<ApiResponse>(endpoint, { params }).subscribe(
      (response) => {
        if (response.isSuccess && response.data) {
          // Populate data from the response
          this.jobDetails = response.data.jobDetails;
          this.jobKeywords = response.data.jobKeywords;
          this.matchingCandidates = response.data.matchingCandidates.sort((a, b) => b.score - a.score);
          this.collectionSize = response.data.total;
        } else {
          this.errorMessage = response.message || 'No data available';
          this.jobDetails = null;
          this.jobKeywords = [];
          this.matchingCandidates = [];
          this.collectionSize = 0;
        }
        this.loading = false;
      },
      (error) => {
        console.error('API Error:', error);
        this.errorMessage = error.error?.message || 'An error occurred while fetching data';
        this.loading = false;
      }
    );
  }
  isKeywordMatch(keyword: string): boolean {
    const normalizedJobKeywords = this.jobKeywords.map(jk => jk.trim().toLowerCase());
    return normalizedJobKeywords.includes(keyword.trim().toLowerCase());
  }

  onPageChange(newPage: number): void {
    this.page = newPage;
    this.onSearch(this.page, this.pageSize);
  }

  onPageSizeChange(newPageSize: number): void {
    this.pageSize = newPageSize;
    this.page = 1; // Reset to the first page when changing page size
    this.onSearch(this.page, this.pageSize);
  }
}
