import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Deduction } from 'src/app/models/Deduction';

@Component({
  selector: 'app-pending-deductions',
  templateUrl: './pending-deductions.component.html',
  styleUrls: ['./pending-deductions.component.css']
})

export class PendingDeductionsComponent implements OnInit {
  loadingDeductions:boolean=true;
  selected:string='pending-deductions'
  mobileView: boolean = false;
  pendingDeduction:Deduction[]=[];
  message:string='';
  removeDeductionFlag:boolean=false;
  editDeductionFlag:boolean=false;
  deductionToMoved:any;
  filter: { id: number | null; candidateName: string; name: string; note: string } = {
    id: null,
    candidateName: '',
    name: '',
    note: ''
  };
  filteredDeductions:any;
  editData!: Deduction;
  isSubmitting = false;
  submissionMessage: string | null = null;
  isSuccess = false;
  isFormSubmitted = false; // Flag to check if form is submitted
  isLoading = false; // Flag to check if loading is in 
  showValidationError = false;
  submitFailed = false;

  constructor(private http:HttpClient,private route: ActivatedRoute) {
    this.route.params.subscribe((params) => {
      if (window.screen.width <= 768) {
        this.toggleSidebar();
      }
    });
   }

  ngOnInit(): void {
    this.getPendingDeductions();
  }

  applyFilter() {
    this.filteredDeductions = this.pendingDeduction.filter((item: { uid: { toString: () => string | any[]; }; candidateName: string; name: string; note: string; }) => {
      const filterIdMatch = this.filter.id === null || item.uid.toString().includes(this.filter.id.toString());
      const filterCandidateNameMatch = !this.filter.candidateName || item.candidateName.toLowerCase().includes(this.filter.candidateName.toLowerCase());
      const filterNameMatch = !this.filter.name || item.name.toLowerCase().includes(this.filter.name.toLowerCase());
      const filterNoteMatch = !this.filter.note || item.note.toLowerCase().includes(this.filter.note.toLowerCase());      
      return filterIdMatch && filterCandidateNameMatch && filterNameMatch && filterNoteMatch;
    });
  }

  editDeduction(data: Deduction) {
  this.clearErrors();
  this.isFormSubmitted=false;
  this.removeDeductionFlag=false;
  data.date_from = data.date_from ? data.date_from.split('T')[0] : '';
  data.date_to = data.date_to ? data.date_to.split('T')[0] : '';
  this.editData = data;
  this.editDeductionFlag = true;
  } 
  
  toggleSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.toggle('sbar_collapsed');
  }
  closeSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.add('sbar_collapsed');
  }

  getPendingDeductions() {
    this.loadingDeductions = true;
    const url = `${environment.apiConfig.uri}/admin/Candidates/GetAddedDeductions`;    
    this.http.get(url).subscribe(
      (response: any) => {
        this.pendingDeduction = response.data.filter((data:Deduction)=>data.status===1) || []; 
        this.pendingDeduction.sort((a:any, b:any) => b.id - a.id);
        this.filteredDeductions = this.pendingDeduction;

        this.loadingDeductions = false;
      },
      (error) => {
        console.error("Error fetching deductions:", error);
        this.loadingDeductions = false;
      }
    );
    this.filter.candidateName='';
    this.filter.id=null;
    this.filter.name='';
    this.filter.note='';
  }

  removededuction(data: Deduction) {
    this.editDeductionFlag=false;
    this.removeDeductionFlag=true;
    this.deductionToMoved=data;
    this.message='Are you sure you want to remove this deduction? This will remove it from the list.'; 
  }

  editDataErrors = {
    startDate: '',
    amount: '',
    limit_fixed: '',
  };

  submitEditDeduction() {
    this.clearErrors();
    const errors = this.validateDeduction(this.editData);
    if (errors.length > 0) {
      this.showValidationError = true;
      this.isLoading = false;
      return;
    }
    this.showValidationError = false;
    this.isLoading = true;
    const payload: any = {
    id: this.editData.id,
    adminId: this.editData.adminId,
    candidateName: this.editData.candidateName,
    employer: this.editData.employer,
    pdid: this.editData.pdid,
    uid: this.editData.uid,
    name: this.editData.name,
    limit_type: this.editData.limit_type,
    status: this.editData.status,
    liability_account_code: this.editData.liability_account_code,
    candidateId: this.editData.candidateId,
    batchId: this.editData.batchId,
    }
    if (this.editData.date_from) payload.date_from = this.editData.date_from;
    if (this.editData.amount) payload.amount = this.editData.amount?.toString();
    if (this.editData.limit_fixed) payload.limit_fixed = this.editData.limit_fixed;
    if (this.editData.note) payload.note = this.editData.note;
    if (this.editData.amount_taken) payload.amount_taken = this.editData.amount_taken;
    if (this.editData.createdAt) payload.createdAt = this.editData.createdAt;
    if (this.editData.date_to) {payload.date_to = this.editData.date_to;}
    if (this.editData.isAstute) payload.isAstute = this.editData.isAstute;
    const url = `${environment.apiConfig.uri}/admin/Candidates/EditDeduction`;
    this.http.post(url, payload).subscribe(
      response => {
        this.isLoading = false;
        this.isFormSubmitted = true;
        this.message = 'Deduction updated successfully';
        this.getPendingDeductions();
      },
      error => {
        this.isLoading = false;
        this.submitFailed = true; 
        this.message = 'Failed to update deduction';
      }
    );
  }
  
  validateDeduction(data: Deduction): string[] {
    const errors: string[] = [];
     // Convert amount and limit_fixed to numbers for proper comparison
    const validatedAmount = parseFloat(data.amount);
    const validatedLimitFixed = parseFloat(data.limit_fixed);
    // Validation for "recurring"
    if (data.limit_type === 'unlimited') {
      if (!data.date_from) {
        this.editDataErrors.startDate = 'Please provide a Start Date.';
        errors.push('Start Date is required.');
      }
      if (!validatedAmount) {
        this.editDataErrors.amount = 'Enter a valid Amount with a maximum of 6 digits.';
        errors.push('Amount is required.');
      }
    }
  
    if (data.limit_type === 'fixed_amount' || data.limit_type === 'fixed_pay_period') {
      if (!data.date_from) {
        this.editDataErrors.startDate = 'Please provide a Start Date.';
        errors.push('Start Date is required.');
      }
      if (!validatedAmount) {
        this.editDataErrors.amount = 'Enter a valid Amount with a maximum of 6 digits.';
        errors.push('Amount is required.');
      }
      if (!validatedLimitFixed) {
        this.editDataErrors.limit_fixed = 'Enter a valid Limit with a maximum of 7 characters.';
        errors.push('Limit is required.');
      }
      if (validatedAmount > validatedLimitFixed) {
        this.editDataErrors.amount = 'Amount cannot be greater than the deduction limit.';
        errors.push('Amount cannot be greater than limit.');
      }
    }
    return errors;
  }
  
  clearErrors() {
    this.editDataErrors = {
      startDate: '',
      amount: '',
      limit_fixed: '',
    };
  }

  formatDate(date: any): string {
    const d = new Date(date);
    return d.toISOString().split('T')[0];
  }

  confirmCancel(){
    const data=this.deductionToMoved;
    const PDID=data.pdid;
    const UID=data.uid;
    const id =data.id;
    const batchId = data.batchId ? `&batchId=${data.batchId}` : '';
    const url = `${environment.apiConfig.uri}/admin/Candidates/DeleteAllDeductions?id=${id}&PDID=${PDID}&UID=${UID}${batchId}`;
    
    this.http.delete(url).subscribe(() => {
      this.getPendingDeductions()
        this.message='Deduction deleted successfully.'
      },
      error => {
        console.error('Error deleting deduction:', error);
        this.message='Error deleting deduction:'
      }
    );

  }

}
